@use '@angular/material' as mat

// @include mat.core()

  $theme: mat.define-theme
    color
      theme-type: dark,
      primary: mat.$violet-palette,
    
    typography
      brand-family: 'Comic Sans',
      bold-weight: 900
    
    density
      scale: -1
  









// @include mat.core()

// $custom-app-primary: #5A2293
// $custom-app-secondary: #A6C63C
// $custom-app-warn: mat-palette(mat.$violet-palette)

// $custom-theme: mat-light-theme($custom-app-primary, $custom-app-secondary, $custom-app-warn)

// @include angular-material-theme($custom-theme)

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline
  font-family: Rubik,sans-serif

html, body 
    overscroll-behavior-y: none
    
    

::ng-deep mat-label
  font-size: 18px
  font-weight: 500
  color: #9E9E9E!important

  &.mdc-floating-label--float-above
    color: red!important


.mdc-text-field
  padding-left: 0!important
  font-weight: bold

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, main, nav, section 
  display: block

body 
  line-height: 1
  

ol, ul 
  list-style: none

blockquote, q 
  quotes: none

blockquote:before, blockquote:after,
q:before, q:after 
  content: ''
  content: none

table 
  border-collapse: collapse
  border-spacing: 0


html,
body
    height: 100%

    display: flex
    flex-direction: column
    

    main
        display: flex
        flex: 1

button
  &:hover
    cursor: pointer!important  


// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  background-color: white !important


// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay 
  background-color: transparent !important

//all error standards
.mat-mdc-form-field-error 
  font-size: 12px !important
  letter-spacing: 0.4px !important
  -webkit-font-smoothing: auto !important

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none!important
  margin: 0!important


/* Firefox */
input[type=number] 
  -moz-appearance: textfield!important


.error
  color:  #f44336!important


@keyframes button-spinner 
  to 
    transform: rotate(360deg)


.button-spinner:before 
  content: ''
  box-sizing: border-box
  position: absolute
  top: 50%
  left: 50%
  width: 30px
  height: 30px
  margin-top: -15px
  margin-left: -10px
  border-radius: 50%
  border: 2px solid #ffffff
  border-top-color: #5A2293
  animation: button-spinner .8s linear infinite


button

    &:disabled
      background-color: #d1d1d1!important

      &:hover
        cursor: not-allowed !important