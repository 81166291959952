
    /* For use in src/lib/core/theming/_palette.scss */
$custom-accent: (
    50 : #eee3f9,
    100 : #d4baf1,
    200 : #b78ce7,
    300 : #9a5edd,
    400 : #843cd6,
    500 : #6e19cf,
    600 : #6616ca,
    700 : #5b12c3,
    800 : #510ebd,
    900 : #3f08b2,
    A100 : #e6ddff,
    A200 : #c1aaff,
    A400 : #9c77ff,
    A700 : #895dff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$custom-primary :$custom-accent

/* For use in src/lib/core/theming/_palette.scss */
// $custom-primary: (
//     50 : #f4f8e8,
//     100 : #e4eec5,
//     200 : #d3e39e,
//     300 : #c1d777,
//     400 : #b3cf59,
//     500 : #a6c63c,
//     600 : #9ec036,
//     700 : #95b92e,
//     800 : #8bb127,
//     900 : #7ba41a,
//     A100 : #f3ffda,
//     A200 : #e3ffa7,
//     A400 : #d2ff74,
//     A700 : #caff5b,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #000000,
//         400 : #000000,
//         500 : #000000,
//         600 : #000000,
//         700 : #000000,
//         800 : #000000,
//         900 : #000000,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #000000,
//         A700 : #000000,
//     )
// );

