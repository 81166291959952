// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


@import '/src/custom-color.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$custom-app-primary: mat.define-palette($custom-primary);
$custom-app-accent: mat.define-palette($custom-accent, 500, A100, A400);

// The warn palette is optional (defaults to red).
$custom-app-warn: mat.define-palette(mat.$red-palette);

$primary300: mat.define-palette($custom-primary, 300);
$primary500: mat.define-palette($custom-primary, 500);
$primary800: mat.define-palette($custom-primary, 800);

$accent300: mat.define-palette($custom-primary, 300);
$accent800: mat.define-palette($custom-primary, 800);

$warn: mat.define-palette($custom-app-warn, 500);

$custom-typography: mat.define-typography-config(
  $font-family: "Rubik, sans-serif"

);




// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$custom-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-app-primary,
      accent: $custom-app-accent,
      warn: $custom-app-warn,
    ),
    typography: $custom-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($custom-app-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Rubik, sans-serif;
}

